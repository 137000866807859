import React, {Component} from 'react';
import {IProps, IState} from "./types/appTypes";
import I18n from "i18next";
import {
    authorized,
    redirect,
    errorConnect,
    getUsersConfiguration,
    disconnect,
    onCallWith,
    videoCallId
} from "./utils/api/subscribe"
import {authorize, setUserConfiguration, isSocketConnected, sendCallWith} from "./utils/api/send";
import {authorizedData} from "./utils/api/subscriptTypes"
import moment from "moment";
import NotificationSystem from 'react-notification-system';
import * as Notification from "./components/Notification";
import {populateAttachments} from "./controllers/attachment";
import {getParticipant} from "./controllers/participant";
import Settings from "./components/Settings";
import Authorized from "./containers/Authorized";
import Waiting from "./containers/Waiting";
import './assets/scss/style.scss?v=2.3';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {connect} from 'react-redux'
import {
    AuthorizationSuccess,
    CallId,
    LoggedDataUpdate,
    ReviewIssues
} from "./modules/Actions";
import ChangeLanguage from "./components/ChangeLanguage";
import {withTranslation} from "react-i18next";
import {getIssues} from "./utils/reviews";
import {info} from "./components/Notification";

class App extends Component<IProps, IState> {

    protected notifications: any;

    constructor(props: IProps) {
        super(props);
        this.state = {
            token: (props.token ? props.token : ''),
            jwtToken: props.token,
            socketId: '',
            status: 0,
            attachments: null,
            authUser: null,
            remoteUser: null,
            reconnecting: false,
            isStarted: false,
            isMic: false,
            isCam: false,
            loggedData: {
                authUserConf: false,
                authCamId: '',
                authMicId: '',
                remoteUserConf: false,
                remoteUserActive: false
            },
        };

        this.catchConnections();
        this.getUsersConfiguration();

        this.notifications = React.createRef();
        Notification.setContainer(this.notifications);

        window.onbeforeunload = function () {
            return I18n.t('app:onbeforeunload')
        };
    };

    componentDidMount(): void {
        this.authorizeToken();
        this.onAuthorizeToken();

        if (localStorage && localStorage['micId'] && localStorage['camId'] && localStorage['configured']) {
            this.startChat(localStorage['micId'], localStorage['camId']);
        }

        const self = this;
        window.addEventListener('access_confirm', function (event) {
            // @ts-ignore
            const data = event.detail.data
            if (typeof data.stream !== "undefined") {
                data.stream.getTracks().forEach((track: any) => track.stop())
            }

            if (data.micPermissions === true && data.camPermissions === true) {
                self.setState({
                    isCam: true,
                    isMic: true,
                    isStarted: true,
                    loggedData: {
                        ...self.state.loggedData,
                        authCamId: '',
                        authMicId: '',
                        authUserConf: true
                    }
                }, () => {
                    setUserConfiguration(
                        self.state.authUser ? self.state.authUser.uuid : '',
                        self.state.isMic,
                        self.state.isCam,
                        self.state.isStarted,
                    );
                })
            }

        })

        videoCallId((callId: number) => {
            this.props.dispatch(CallId(callId))
        })
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.state.loggedData !== prevState.loggedData) {
            this.props.dispatch(LoggedDataUpdate(this.state.loggedData))
        }
    }

    catchConnections = () => {
        errorConnect((data:any) => {
            console.log('errorConnect');
            // console.log(data);
        })

        disconnect((data:any) => {
            console.log('disconnect')
            // console.log(data);
        })

        window.addEventListener('socket.reconnect', () => {
            if (!isSocketConnected) {
                return;
            }
            this.authorizeToken();
        })
    };

    authorizeToken = () => {
        if (!this.props.token) {
            this.setState({status: -1});
            window.onbeforeunload = function () {
                return null
            };
        }

        if (this.props.token !== '') {
            authorize(this.props.token, sessionStorage.getItem("session"));
        }
    };

    onAuthorizeToken = () => {
        authorized((data: authorizedData) => {
            const {status, room, messages, participants, socketId} = data;
            const authUser = data.participant

            // @ts-ignore
            if (data.code === '401' || data.description === 'jwt expired') {
                this.setState({status: -2});
                return;
            }

            if (status === -1) {
                this.setState({status: status});
                window.onbeforeunload = function () {
                    return null
                };
                return;
            }

            const sessionId = data.sessionId;
            sessionStorage.setItem("session", sessionId + '.' + room.uuid + '.' + authUser.uuid);

            let attachments: any = null;
            if (messages) {
                attachments = populateAttachments(messages);
            }
            let remoteUser = getParticipant(authUser, participants);

            this.setState({
                reconnecting: false,
                status: status,
                room: room,
                messages: messages,
                authUser: authUser,
                remoteUser: remoteUser,
                attachments: attachments,
                socketId: socketId
            }, () => {
                this.props.dispatch(AuthorizationSuccess({
                    token: this.state.token,
                    socketId: socketId,
                    room: room,
                    authUser: authUser,
                    remoteUser: remoteUser,
                    messages: messages,
                    attachments: attachments,
                }))

                if (this.state.room && this.state.authUser) {
                    setUserConfiguration(
                        this.state.authUser.uuid,
                        this.state.isMic,
                        this.state.isCam,
                        this.state.isStarted
                    );
                }

                if (this.state.room && this.state.room.closed) {
                    window.onbeforeunload = function () {
                        return null
                    };
                }

                this.checkAllowCall(data.clients);

                if (data.room.expireAt && (moment() > moment(data.room.expireAt))) {
                    this.setState({status: -2});
                    return;
                }

                // sendConnected(room.uuid, authUser.uuid);
                this.pushChatStartToMessage()
            });

            if (data.callWith && !this.props.isJanusCall && data.janusId && data.janusToken && data.janusUrl && data.janusRoomToken) {
                const joinEvent = new CustomEvent('janus.join', {
                    detail: {
                        janusId: data.janusId,
                        janusToken: data.janusToken,
                        janusUrl: data.janusUrl,
                        janusRoomToken: data.janusRoomToken,
                        remoteSocket: data.callWith
                    }
                });
                window.dispatchEvent(joinEvent);
            }
            if (!data.callWith && this.props.isJanusCall) {
                window.dispatchEvent(new CustomEvent('janus.stop'))
                info(I18n.t('notifications:closedVideo'));
            }

            getIssues(this.props.token).then(r => {
                this.props.dispatch(ReviewIssues(r))
            })
        })

        onCallWith((data: { callWith: string | null }) => {
            sendCallWith(data.callWith)
        })

        redirect((data: { url: string | null }) => {
            if (typeof data.url === 'string') {
                window.location.href = data.url;
            }
        });
    }


    pushChatStartToMessage = () => {
        const messages = this.state.messages;

        if (this.state.room && this.state.room.description && this.state.room.description !== '') {
            messages?.unshift({
                content: (this.state.room && this.state.room.description ? this.state.room.description : ' '),
                createdAt: this.state.room && this.state.room.createdAt ? this.state.room.createdAt : moment().format('YYYY-MM-DD')
            });
        }

        if (this.state.room && this.state.room.welcome && this.state.room.welcome !== '') {
            messages?.unshift({
                content: (this.state.room && this.state.room.welcome ? this.state.room.welcome : ' '),
                createdAt: this.state.room && this.state.room.createdAt ? this.state.room.createdAt : moment().format('YYYY-MM-DD')
            });

            this.setState({messages: messages})
        }
    };


    startChat = (isMic: boolean, isCam: boolean) => {
        this.setState({
            isStarted: true,
            isMic: isMic,
            isCam: isCam
        }, () => {
            if (this.state.room && this.state.authUser) {
                setUserConfiguration(
                    this.state.authUser.uuid,
                    this.state.isMic,
                    this.state.isCam,
                    this.state.isStarted
                );
            }
        });
        localStorage.setItem('configured', '1');
    };

    checkAllowCall = (data: any) => {
        if (!data) return;

        const checkIfConfigured = (confData: any) => {
            if ((confData.microphone || confData.camera)) {
                if (confData.configured) {
                    return true
                }
            }
            return false;
        };

        let authUserConf = false;
        let remoteUserConf = false;
        let remoteUserActive = false;
        let configuredBySocket = '';
        Object.keys(data).forEach((socketId: string) => {
            // @ts-ignore
            let confData = data[socketId];
            if (confData.participant_uuid === this.state?.authUser?.uuid) {
                authUserConf = checkIfConfigured({
                    microphone: this.state.isMic,
                    camera: this.state.isCam,
                    configured: this.state.isStarted
                });
            }
            if (!configuredBySocket && socketId !== this.state.socketId && confData.participant_uuid !== this.state?.authUser?.uuid) {
                remoteUserConf = checkIfConfigured(confData);
                if (remoteUserConf) {
                    configuredBySocket = socketId;
                }
                if (confData.configured) {
                    remoteUserActive = true;
                }
            }
        });

        this.setState({
            loggedData: {
                authUserConf: authUserConf,
                remoteUserConf: remoteUserConf,
                remoteUserActive: remoteUserActive,
                authCamId: localStorage['camId'],
                authMicId: localStorage['micId']
            }
        })
    };

    getUsersConfiguration = () => {
        getUsersConfiguration((socketId: string, data: object) => {
            if (!this.state.socketId) {
                this.setState({socketId: socketId}, () => {
                    this.checkAllowCall(data);
                });
                return;
            }
            this.checkAllowCall(data);
        })
    };

    renderAuthorized = () => {
        if (this.state.isStarted && this.state.room) {
            return <Authorized/>
        } else {
            return (
                <Settings
                    setStart={(isMic: boolean, isCam: boolean) => this.startChat(isMic, isCam)}
                    setMicId={(id: string) => {
                        this.setState(prevState => ({
                            loggedData: {
                                ...prevState.loggedData,
                                authMicId: id,
                            }
                        }))
                    }}
                    setCamId={(id: string) => {
                        this.setState(prevState => ({
                            loggedData : {
                                ...prevState.loggedData,
                                authCamId: id
                            }
                        }))
                    }}
                />
            )
        }
    };

    render() {
        return (
            <div className="_container clear-space-sm">
                <ChangeLanguage/>
                <NotificationSystem
                    ref={this.notifications}
                    style={Notification.style}
                />
                {(this.state.status > 0 && this.state.room && !this.state.reconnecting) || (this.state.reconnecting && this.props.isJanusCall)
                    ? this.renderAuthorized()
                    : <Waiting
                        reconnecting={this.state.reconnecting}
                        status={this.state.status}
                        authUser={this.state.authUser}
                    />
                }
            </div>
        );
    }
}


const mapStateToProps = (state: object) => {
    return state
}

export default withTranslation()(connect(mapStateToProps)(App))
